<template>
    <el-container>
      <div class="loading-logo" v-if="loading">
        <div>
          <h3>加载中，请耐心等待……</h3>
        </div>
      </div>
      <div class="w-100 warpper" v-else>
        <div class="banner">
          <h1>12345热点分析</h1>
          <p><span>报告于2023-12-25 16:46:24自动生成</span></p>
        </div>
        <div class="fxbg rdmw">
          <div class="warpper">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card ov-v">
                  <h3 class="mod-title">网媒相关热点人物</h3>
                  <div class="pad-lr-20 pos-r">
                    <div class="echarts_empty h-350">
                      <img src="../../../assets/images/analysis/v3/hot/rdrw.png" width="487" alt="">
                    </div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card ov-v">
                  <h3 class="mod-title">网媒热门关键词</h3>
                  <div class="pad-lr-20 pos-r">
                    <div class="echarts_empty h-350">
                       <img src="../../../assets/images/analysis/v3/hot/rmcy.png"  width="657" alt="">
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card rdzt">
                  <h3 class="mod-title">网媒热门信息发布地区</h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty">
                       <img src="../../../assets/images/analysis/v3/hot/dqslfb.png" alt="" width="507">
                    </div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card fardmt">
                  <h3 class="mod-title">网媒热点媒体</h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data.sites.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="mod-list progress_no_bg" v-show="data.sites.length >= 1">
                      <ul>
                        <li>
                          <h3><span class="mod-list-title2">媒体名称</span><span>关注指数</span></h3>
                        </li>
                        <li v-for="(row, index) in data['sites']" :key="index">
                          <a class="mod-list-title2" :title="row.name">{{ row.name }}</a>
                          <el-progress :text-inside="true" :stroke-width="14" :percentage="row.value / data['sites'][0].value * 100" :show-text="false"></el-progress>
                          <span class="tag">{{ row.value }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20">
            <el-card class="box-card rdwz">
              <h3 class="mod-title">网媒热点文章</h3>
              <div class="box-shadow"></div>
              <div class="pad-lr-20">
                <div class="echarts_empty" v-show="data.articles.length < 1">
                  <div class="img"></div>
                </div>
                <div class="mod-list progress_no_bg" v-show="data.articles.length >= 1">
                  <ul>
                    <li>
                      <h3><span class="num-tit">序号</span><span class="mod-list-title1">信息标题</span><span>信息摘要</span><span class="tag f-r">热点指数 <el-tooltip effect="dark" placement="bottom"><i class="el-icon-info"></i><div slot="content">透过话题分类模型将文章进行分类，并从热门的话题中使用密度聚<br>类模型挑选出热门文章，最后标准化成1-100分的分数，得分越高<br>者代表越热门</div></el-tooltip></span></h3>
                    </li>
                    <li v-for="(row, index) in data['articlesWangmei']" :key="index">
                      <span class="num bg-555" v-if="index == 0">1</span>
                      <span class="num bg-689" v-else-if="index == 1">2</span>
                      <span class="num bg-8ea" v-else-if="index == 2">3</span>
                      <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                      <a class="mod-list-title1" :href="row.source_url" target="_blank" :title="row.title">{{ row.title }}</a>
                      <p>{{ row.summary }}</p>
                      <el-progress :text-inside="true" :stroke-width="14" :percentage="row.heat / data['articles'][0].heat * 100" :show-text="false"></el-progress>
                      <span class="tag">{{ row.heat }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </el-card>
          </div>
        </div>
        <div class="fxbg rdwb">
          <div class="warpper">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card ov-v">
                  <h3 class="mod-title">微博相关热点人物</h3>
                  <div class="pad-lr-20 pos-r">
                    <div class="echarts_empty h-350">
                       <img src="../../../assets/images/analysis/v3/hot/wbrdrw.png" width="487" alt="">
                    </div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card ov-v">
                  <h3 class="mod-title">微博相关热点机构</h3>
                  <div class="pad-lr-20 pos-r">
                    <div class="echarts_empty h-350">
                       <img src="../../../assets/images/analysis/v3/hot/wbrdjg.png" width="487"  alt="">
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20">
            <el-card class="box-card">
              <h3 class="mod-title">微博热点用户</h3>
              <div class="box-shadow"></div>
              <div class="pad-lr-20">
                <div class="echarts_empty" v-show="data.authors.length < 1">
                  <div class="img"></div>
                </div>
                <div class="w-100 ov-h" v-show="data.authors.length >= 1">
                  <ul class="user_list">
                    <li v-for="(row, index) in data['authors']" :key="index">
                      <div class="flex">
                        <div class="tx">
                          <img :src="row.avatar" class="img">
                          <span class="num top1" v-if="index == 0"></span>
                          <span class="num top2" v-if="index == 1"></span>
                          <span class="num top3" v-if="index == 2"></span>
                          <span class="ico v_red" v-if="row.type == '金V'"></span>
                          <span class="ico v_orange" v-else-if="row.type == '黄V'"></span>
                          <span class="ico v_blue" v-else-if="row.type == '蓝V'"></span>
                          <span class="ico v_pink" v-else-if="row.type == '微博女郎'"></span>
                          <span class="ico v_talent_show" v-else-if="row.type == '达人'"></span>
                        </div>
                        <div class="con">
                          <p> <a :href="row.home" target="_blank" :title="row.name">{{ row.name }}</a> </p>
                          <p>文章数：<em>{{ row.articles }}</em></p>
                          <p>总转发：<em>{{ row.forward }}</em></p>
                          <p>总评论：<em>{{ row.remark }}</em></p>
                          <p>总点赞：<em>{{ row.zan }}</em></p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </el-card>
          </div>
          <div class="warpper pt-20">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card rdzt2">
                  <h3 class="mod-title">微博热点话题</h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data.topics.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="mod-list" v-show="data.topics.length >= 1">
                      <ul>
                        <li>
                          <h3><span class="num">热点指数</span><span class="mod-list-title1">话题</span></h3>
                        </li>
                        <li v-for="(row, index) in data['topics']" :key="index">
                          <span class="num">
                            <var class="ico1" v-if="index == 0"></var>
                            <var class="ico2" v-else-if="index == 1"></var>
                            <var class="ico3" v-else-if="index == 2"></var>
                            <var class="ico4" v-else></var><em>{{ row.heat }}</em>
                          </span>
                          <a class="mod-list-title1" :title="row.topic">{{ row.topic }}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card url_lj">
                  <h3 class="mod-title">微博相关热门文章</h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data.urls.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="mod-list progress_no_bg" v-show="data.urls.length >= 1">
                      <ul>
                        <li>
                          <h3><span class="num-tit">序号</span><span class="mod-list-title1">URL链接</span><span class="tag rdzs">热点指数</span></h3>
                        </li>
                        <li v-for="(row, index) in data['urls']" :key="index">
                          <span class="num bg-555" v-if="index == 0">1</span>
                          <span class="num bg-689" v-else-if="index == 1">2</span>
                          <span class="num bg-8ea" v-else-if="index == 2">3</span>
                          <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                          <a class="mod-list-title1" :href="row.url" target="_blank">{{ row.title }}</a>
                          <el-progress :text-inside="true" :stroke-width="14" :percentage="row.heat / data['urls'][0].heat * 100" :show-text="false"></el-progress>
                          <span class="tag">{{ row.heat }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="fxbg rdwx">
          <div class="warpper">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card ov-v fardmt">
                  <h3 class="mod-title">评论热门来源</h3>
                  <div class="pad-lr-20 pos-r h-350">
                    <div class="mod-list">
                      <ul>
                        <li>
                          <h3><span class="mod-list-title2">媒体名称</span><span>关注指数</span></h3>
                        </li>
                        <li v-for="(row, index) in data['sites2']" :key="index">
                          <a class="mod-list-title2" :title="row.name">{{ row.name }}</a>
                          <el-progress :text-inside="true" :stroke-width="14" :percentage="row.value / data['sites2'][0].value * 100" :show-text="false"></el-progress>
                          <span class="tag">{{ row.value }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card ov-v">
                  <h3 class="mod-title">评论热门关键词</h3>
                  <div class="pad-lr-20 pos-r">
                    <div class="echarts_empty h-350">
                      <img src="../../../assets/images/analysis/v3/hot/weibo_rmcy.png" alt="" width="487" height="286">
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card rdzt2">
                  <h3 class="mod-title">评论热门话题</h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data.topics.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="mod-list" v-show="data.topics.length >= 1">
                      <ul>
                        <li>
                          <h3><span class="num">热点指数</span><span class="mod-list-title1">话题</span></h3>
                        </li>
                        <li v-for="(row, index) in data['topics2']" :key="index">
                          <span class="num">
                            <var class="ico1" v-if="index == 0"></var>
                            <var class="ico2" v-else-if="index == 1"></var>
                            <var class="ico3" v-else-if="index == 2"></var>
                            <var class="ico4" v-else></var><em>{{ row.heat }}</em>
                          </span>
                          <a class="mod-list-title1" :title="row.topic">{{ row.topic }}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card url_lj comment_article">
                  <h3 class="mod-title">评论热门文章</h3>
                  <div class="pad-lr-20">
                    <div class="mod-list progress_no_bg">
                      <ul>
                        <li>
                          <h3><span class="num-tit">序号</span><span class="mod-list-title1">信息内容</span><span class="tag rdzs">信息来源</span></h3>
                        </li>
                        <li v-for="(row, index) in data['articlesPl']" :key="index">
                          <span class="num bg-555" v-if="index == 0">1</span>
                          <span class="num bg-689" v-else-if="index == 1">2</span>
                          <span class="num bg-8ea" v-else-if="index == 2">3</span>
                          <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                          <a class="mod-list-title1" :href="row.title" target="_blank">{{ row.title }}</a>
                          <span class="tag rdzs">{{ row.source_name }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20 hide">
            <el-card class="box-card rdwz">
              <h3 class="mod-title">方案微信公众号热点文章</h3>
              <div class="box-shadow"></div>
              <div class="pad-lr-20">
                <div class="echarts_empty" v-show="data.articles3.length < 1">
                  <div class="img"></div>
                </div>
                <div class="mod-list progress_no_bg" v-show="data.articles3.length >= 1">
                  <ul>
                    <li>
                      <h3><span class="num-tit">序号</span><span class="mod-list-title1">信息标题</span><span>信息摘要</span><span class="tag f-r">热点指数 <el-tooltip effect="dark" placement="bottom"><i class="el-icon-info"></i><div slot="content">透过话题分类模型将文章进行分类，并从热门的话题中使用密度聚<br>类模型挑选出热门文章，最后标准化成1-100分的分数，得分越高<br>者代表越热门</div></el-tooltip></span></h3>
                    </li>
                    <li v-for="(row, index) in data['articles3']" :key="index">
                      <span class="num bg-555" v-if="index == 0">1</span>
                      <span class="num bg-689" v-else-if="index == 1">2</span>
                      <span class="num bg-8ea" v-else-if="index == 2">3</span>
                      <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                      <a class="mod-list-title1" :href="row.source_url" target="_blank" :title="row.title">{{ row.title }}</a>
                      <p>{{ row.summary }}</p>
                      <el-progress :text-inside="true" :stroke-width="14" :percentage="row.heat / data['articles'][0].heat * 100" :show-text="false"></el-progress>
                      <span class="tag">{{ row.heat }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </el-card>
          </div>
        </div>

        <div class="fxbg rdwx">
          <div class="warpper">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card ov-v fardmt">
                  <h3 class="mod-title">热门机构</h3>
                  <div class="pad-lr-20 pos-r h-350">
                    <div class="echarts_empty">
                      <img src="../../../assets/images/analysis/v3/hot/rdjg.png" width="487" alt="">
                    </div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card rdzt">
                  <h3 class="mod-title">活跃APP</h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty h-350">
                      <img src="../../../assets/images/analysis/v3/hot/hyapp.png" alt="" width="407"/>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card url_lj comment_article">
                  <h3 class="mod-title">微信热文</h3>
                  <div class="pad-lr-20">
                    <div class="mod-list progress_no_bg">
                      <ul>
                        <li>
                          <h3><span class="num-tit">序号</span><span class="mod-list-title1">信息内容</span><span class="tag rdzs">信息来源</span></h3>
                        </li>
                        <li v-for="(row, index) in data['articlesWeixin']" :key="index">
                          <span class="num bg-555" v-if="index == 0">1</span>
                          <span class="num bg-689" v-else-if="index == 1">2</span>
                          <span class="num bg-8ea" v-else-if="index == 2">3</span>
                          <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                          <a class="mod-list-title1" :href="row.title" target="_blank">{{ row.title }}</a>
                          <span class="tag rdzs">{{ row.source_name }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card url_lj comment_article">
                  <h3 class="mod-title">微博热文</h3>
                  <div class="pad-lr-20">
                    <div class="mod-list progress_no_bg">
                      <ul>
                        <li>
                          <h3><span class="num-tit">序号</span><span class="mod-list-title1">信息内容</span><span class="tag rdzs">信息来源</span></h3>
                        </li>
                        <li v-for="(row, index) in data['articlesWeibo']" :key="index">
                          <span class="num bg-555" v-if="index == 0">1</span>
                          <span class="num bg-689" v-else-if="index == 1">2</span>
                          <span class="num bg-8ea" v-else-if="index == 2">3</span>
                          <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                          <a class="mod-list-title1" :href="row.title" target="_blank">{{ row.title }}</a>
                          <span class="tag rdzs">{{ row.source_name }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>

          <div class="warpper pt-20">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card url_lj comment_article">
                  <h3 class="mod-title">报纸热文</h3>
                  <div class="pad-lr-20">
                    <div class="mod-list progress_no_bg">
                      <ul>
                        <li>
                          <h3><span class="num-tit">序号</span><span class="mod-list-title1">信息内容</span><span class="tag rdzs">信息来源</span></h3>
                        </li>
                        <li v-for="(row, index) in data['articlesBaokan']" :key="index">
                          <span class="num bg-555" v-if="index == 0">1</span>
                          <span class="num bg-689" v-else-if="index == 1">2</span>
                          <span class="num bg-8ea" v-else-if="index == 2">3</span>
                          <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                          <a class="mod-list-title1" :href="row.title" target="_blank">{{ row.title }}</a>
                          <span class="tag rdzs">{{ row.source_name }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card url_lj comment_article">
                  <h3 class="mod-title">APP热文</h3>
                  <div class="pad-lr-20">
                    <div class="mod-list progress_no_bg">
                      <ul>
                        <li>
                          <h3><span class="num-tit">序号</span><span class="mod-list-title1">信息内容</span><span class="tag rdzs">信息来源</span></h3>
                        </li>
                        <li v-for="(row, index) in data['articlesApp']" :key="index">
                          <span class="num bg-555" v-if="index == 0">1</span>
                          <span class="num bg-689" v-else-if="index == 1">2</span>
                          <span class="num bg-8ea" v-else-if="index == 2">3</span>
                          <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                          <a class="mod-list-title1" :href="row.title" target="_blank">{{ row.title }}</a>
                          <span class="tag rdzs">{{ row.source_name }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card url_lj comment_article">
                  <h3 class="mod-title">短视频热文</h3>
                  <div class="pad-lr-20">
                    <div class="mod-list progress_no_bg">
                      <ul>
                        <li>
                          <h3><span class="num-tit">序号</span><span class="mod-list-title1">信息内容</span><span class="tag rdzs">信息来源</span></h3>
                        </li>
                        <li v-for="(row, index) in data['articlesDuanShipin']" :key="index">
                          <span class="num bg-555" v-if="index == 0">1</span>
                          <span class="num bg-689" v-else-if="index == 1">2</span>
                          <span class="num bg-8ea" v-else-if="index == 2">3</span>
                          <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                          <a class="mod-list-title1" :href="row.title" target="_blank">{{ row.title }}</a>
                          <span class="tag rdzs">{{ row.source_name }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card url_lj comment_article">
                  <h3 class="mod-title">头条号热文</h3>
                  <div class="pad-lr-20">
                    <div class="mod-list progress_no_bg">
                      <ul>
                        <li>
                          <h3><span class="num-tit">序号</span><span class="mod-list-title1">信息内容</span><span class="tag rdzs">信息来源</span></h3>
                        </li>
                        <li v-for="(row, index) in data['articlesToutiao']" :key="index">
                          <span class="num bg-555" v-if="index == 0">1</span>
                          <span class="num bg-689" v-else-if="index == 1">2</span>
                          <span class="num bg-8ea" v-else-if="index == 2">3</span>
                          <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                          <a class="mod-list-title1" :href="row.title" target="_blank">{{ row.title }}</a>
                          <span class="tag rdzs">{{ row.source_name }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card url_lj comment_article">
                  <h3 class="mod-title">知乎热文</h3>
                  <div class="pad-lr-20">
                    <div class="mod-list progress_no_bg">
                      <ul>
                        <li>
                          <h3><span class="num-tit">序号</span><span class="mod-list-title1">信息内容</span><span class="tag rdzs">信息来源</span></h3>
                        </li>
                        <li v-for="(row, index) in data['articlesZhihu']" :key="index">
                          <span class="num bg-555" v-if="index == 0">1</span>
                          <span class="num bg-689" v-else-if="index == 1">2</span>
                          <span class="num bg-8ea" v-else-if="index == 2">3</span>
                          <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                          <a class="mod-list-title1" :href="row.title" target="_blank">{{ row.title }}</a>
                          <span class="tag rdzs">{{ row.source_name }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card url_lj comment_article">
                  <h3 class="mod-title">视频网站热文</h3>
                  <div class="pad-lr-20">
                    <div class="mod-list progress_no_bg" style="height: 215px" >
                      <ul>
                        <li>
                          <h3><span class="num-tit">序号</span><span class="mod-list-title1">信息内容</span><span class="tag rdzs">信息来源</span></h3>
                        </li>
                        <li v-for="(row, index) in data['articlesShipin']" :key="index">
                          <span class="num bg-555" v-if="index == 0">1</span>
                          <span class="num bg-689" v-else-if="index == 1">2</span>
                          <span class="num bg-8ea" v-else-if="index == 2">3</span>
                          <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                          <a class="mod-list-title1" :href="row.title" target="_blank">{{ row.title }}</a>
                          <span class="tag rdzs">{{ row.source_name }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
        </div>

        <div class="fxbg rdwx">
          <div class="warpper">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card ov-v fardmt">
                  <h3 class="mod-title">渠道热搜</h3>
                  <div class="pad-lr-20 pos-r h-350">
                    <div class="mod-list">
                      <ul>
                        <li>
                          <h3><span class="mod-list-title2">媒体名称</span><span>关注指数</span></h3>
                        </li>
                        <li v-for="(row, index) in data['sites2']" :key="index">
                          <a class="mod-list-title2" :title="row.name">{{ row.name }}</a>
                          <el-progress :text-inside="true" :stroke-width="14" :percentage="row.value / data['sites2'][0].value * 100" :show-text="false"></el-progress>
                          <span class="tag">{{ row.value }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card ov-v fardmt">
                  <h3 class="mod-title">活跃网站</h3>
                  <div class="pad-lr-20 pos-r h-350">
                    <div class="mod-list">
                      <ul>
                        <li>
                          <h3><span class="mod-list-title2">媒体名称</span><span>占比%</span></h3>
                        </li>
                        <li v-for="(row, index) in data['sites3']" :key="index">
                          <a class="mod-list-title2" :title="row.name">{{ row.name }}</a>
                          <el-progress :text-inside="true" :stroke-width="14" :percentage="row.value / data['sites2'][0].value * 100" :show-text="false"></el-progress>
                          <span class="tag">{{ row.value }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card url_lj comment_article">
                  <h3 class="mod-title">热点互动</h3>
                  <div class="pad-lr-20">
                    <div class="mod-list progress_no_bg">
                      <ul>
                        <li>
                          <h3><span class="num-tit">序号</span><span class="mod-list-title1">信息内容</span><span class="tag rdzs">信息来源</span></h3>
                        </li>
                        <li v-for="(row, index) in data['articlesPl']" :key="index">
                          <span class="num bg-555" v-if="index == 0">1</span>
                          <span class="num bg-689" v-else-if="index == 1">2</span>
                          <span class="num bg-8ea" v-else-if="index == 2">3</span>
                          <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                          <a class="mod-list-title1" :href="row.title" target="_blank">{{ row.title }}</a>
                          <span class="tag rdzs">{{ row.source_name }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card url_lj comment_article">
                  <h3 class="mod-title">活跃论坛</h3>
                  <div class="pad-lr-20">
                    <div class="mod-list progress_no_bg">
                      <ul>
                        <li>
                          <h3><span class="num-tit">序号</span><span class="mod-list-title1">信息内容</span><span class="tag rdzs">信息来源</span></h3>
                        </li>
                        <li v-for="(row, index) in data['articlesLuntan']" :key="index">
                          <span class="num bg-555" v-if="index == 0">1</span>
                          <span class="num bg-689" v-else-if="index == 1">2</span>
                          <span class="num bg-8ea" v-else-if="index == 2">3</span>
                          <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                          <a class="mod-list-title1" :href="row.title" target="_blank">{{ row.title }}</a>
                          <span class="tag rdzs">{{ row.source_name }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card url_lj comment_article">
                  <h3 class="mod-title">热点事件</h3>
                  <div class="pad-lr-20">
                    <div class="mod-list progress_no_bg" style="height: 215px;">
                      <ul>
                        <li>
                          <h3><span class="num-tit">序号</span><span class="mod-list-title1">信息内容</span><span class="tag rdzs">信息来源</span></h3>
                        </li>
                        <li v-for="(row, index) in data['articlesRdsj']" :key="index">
                          <span class="num bg-555" v-if="index == 0">1</span>
                          <span class="num bg-689" v-else-if="index == 1">2</span>
                          <span class="num bg-8ea" v-else-if="index == 2">3</span>
                          <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                          <a class="mod-list-title1" :href="row.title" target="_blank">{{ row.title }}</a>
                          <span class="tag rdzs">{{ row.source_name }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card rdzt2">
                  <h3 class="mod-title">热点主题</h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data.topics.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="mod-list" v-show="data.topics.length >= 1">
                      <ul>
                        <li>
                          <h3><span class="num">热点指数</span><span class="mod-list-title1">话题</span></h3>
                        </li>
                        <li v-for="(row, index) in data['topics3']" :key="index">
                          <span class="num">
                            <var class="ico1" v-if="index == 0"></var>
                            <var class="ico2" v-else-if="index == 1"></var>
                            <var class="ico3" v-else-if="index == 2"></var>
                            <var class="ico4" v-else></var><em>{{ row.heat }}</em>
                          </span>
                          <a class="mod-list-title1" :title="row.topic">{{ row.topic }}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </el-card>
              </el-col>

            </el-row>
          </div>
        </div>


      </div>
    </el-container>
</template>
<script type="application/javascript">
  const d3 = require("d3");
  export default {
    name: "analysis-network",
    data() {
      return {
        title: "",
        data: {
          "orgs": [
            {
              "name": "百度",
              "value": 164
            },
            {
              "name": "搜狐",
              "value": 20
            },
            {
              "name": "红星新闻",
              "value": 17
            },
            {
              "name": "锤子科技",
              "value": 13
            },
            {
              "name": "网易",
              "value": 10
            },
            {
              "name": "网易科技",
              "value": 10
            },
            {
              "name": "搜狐董事局",
              "value": 10
            },
            {
              "name": "小米集团",
              "value": 10
            },
            {
              "name": "智东西",
              "value": 9
            },
            {
              "name": "华为",
              "value": 9
            }
          ],
          "persons": [
            {
              "name": "李彦宏",
              "value": 324
            },
            {
              "name": "张朝阳",
              "value": 48
            },
            {
              "name": "马云",
              "value": 9
            },
            {
              "name": "王海峰",
              "value": 9
            },
            {
              "name": "直男上树",
              "value": 6
            },
            {
              "name": "张全蛋",
              "value": 5
            },
            {
              "name": "刘凯",
              "value": 5
            },
            {
              "name": "罗永浩",
              "value": 4
            },
            {
              "name": "雷军",
              "value": 4
            }
          ],
          "orgs2": [
            {
              "name": "百度",
              "value": 10
            },
            {
              "name": "朝阳区看守所",
              "value": 1
            },
            {
              "name": "奥林匹克公园派出所",
              "value": 1
            }
          ],
          "persons2": [
            {
              "name": "李彦宏",
              "value": 26
            },
            {
              "name": "直男上树",
              "value": 20
            },
            {
              "name": "罗永浩",
              "value": 10
            },
            {
              "name": "程冠旗",
              "value": 5
            },
            {
              "name": "吴涛",
              "value": 1
            },
            {
              "name": "阮煜琳",
              "value": 1
            }
          ],
          "sites": [
            {"name": "新浪微博","value":1154},
            {"name": "微信","value":792},
            {"name": "懂车帝","value":170},
            {"name": "搜狐号","value":136},
            {"name": "今日头条","value":89},
            {"name": "今日头条微头条","value":86},
            {"name": "百家号","value":79},
            {"name": "网易号","value":67},
            {"name": "新浪网","value":63},
            {"name": "腾讯新闻","value":42}
          ],
          "sites2": [
            {"name": "今日头条","value":100},
            {"name": "新浪微博","value":41},
            {"name": "搜狐号","value":38},
            {"name": "趣头条","value":15},
            {"name": "网易新闻","value":8},
            {"name": "抖音","value":6},
            {"name": "小红书","value":6},
            {"name": "企鹅号","value":2},
          ],
          "sites3": [
            {"name": "懂车帝","value":'8.44'},
            {"name": "搜狐号","value": '8.06'},
            {"name": "今日头条","value": '5.36'},
            {"name": "网易号","value": '4.89'},
            {"name": "百家号","value": '3.69'},
            {"name": "新浪网","value": '2.68'},
            {"name": "腾讯新闻","value": '2.5'},
            {"name": "澎湃新闻","value": '1.97'}
          ],
          "topics": [
            {
              "heat": 100,
              "topic": "#普陀长征获评“博爱家园（街镇）”#",
              "source_url": "https://j.021east.com/p/1703398948037889"
            },
            {
              "heat": 88,
              "topic": "#上海市长热线给康桥黑恶势力通风报信#",
              "source_url": "https://weibo.com/7439767458/NyKfaw9b7"
            },
            {
              "heat": 83,
              "topic": "#房东不退押金#",
              "source_url": "https://weibo.com/5453071704/NyEXsqQDQ"
            },
            {
              "heat": 79,
              "topic": "#求女儿朱小雪被害真相# ",
              "source_url": "https://weibo.com/7497349931/NyAmNxn9l"
            },
            {
              "heat": 71,
              "topic": "#寒潮“冻”真格#",
              "source_url": "https://k.sina.cn/article_2541763545_97803bd902001ayb2.html"
            },
            // {
            //   "heat": 70,
            //   "topic": "#河南挺住#",
            //   "source_url": "https://www.jiemian.com/article/3274746.html"
            // },
            // {
            //   "heat": 68,
            //   "topic": "#河南一定行#",
            //   "source_url": "https://health.ifeng.com/c/7o0WoE3maGP"
            // },
            // {
            //   "heat": 64,
            //   "topic": "#河南多地暴雨致灾#",
            //   "source_url": "http://www.yibencaijing.net/caijing/31127951.html"
            // },
            // {
            //   "heat": 55,
            //   "topic": "#新乡暴雨",
            //   "source_url": "http://www.qi-wen.com/html/31135225.html"
            // },
            // {
            //   "heat": 48,
            //   "topic": "#暴雨#",
            //   "source_url": "http://nb.zol.com.cn/721/7210388_all.html"
            // }
          ],
          "topics2": [
            {
              "heat": 581,
              "topic": "#租房子如果房东恶意不退押金没办法要回吗#",
              "source_url": "https://weibo.com/1457988713/NyEfNzvbQ"
            },
            {
              "heat": 235,
              "topic": "#上海市长热线给康桥黑恶势力通风报信#",
              "source_url": "https://weibo.com/7497297814/NyJh2olB7"
            },
            // {
            //   "heat": 83,
            //   "topic": "#房东不退押金#",
            //   "source_url": "https://weibo.com/5453071704/NyEXsqQDQ"
            // },
            {
              "heat": 124,
              "topic": "#求女儿朱小雪被害真相# ",
              "source_url": "https://weibo.com/7497349931/NyAmNxn9l"
            },
            // {
            //   "heat": 71,
            //   "topic": "#寒潮“冻”真格#",
            //   "source_url": "https://k.sina.cn/article_2541763545_97803bd902001ayb2.html"
            // },
          ],
          "topics3": [
            {
              "heat": 189,
              "topic": "#打通服务群众的“最后一公里”#",
              "source_url": "#"
            },
            {
              "heat": 125,
              "topic": "#极端天气的应急预案#",
              "source_url": "#"
            },
            {
              "heat": 89,
              "topic": "#小区里的“微型消防站”#",
              "source_url": "#"
            },
            {
              "heat": 42,
              "topic": "#上海市公开曝光典型#",
              "source_url": "#"
            },
            {
              "heat": 19,
              "topic": "#聚微光传温暖#",
              "source_url": "#"
            },
          ],
          "articlesWangmei": [
            {"heat":189,"title":"冬日里的暖心表扬，普陀这里的加梯助力美好","summary":"普陀这里的加梯助力美好生活 近日，桃浦镇热线办收到了童女士的12345热线表扬。童女士对镇加梯办的工作表示了深深的感谢，她表示，加梯工程为楼里居民的冬日出行带来了极大的便利。童女士家住雪松路458弄雪松苑164号，这是一栋6层的老式售后公房。","source_name": "抖音","source_url":"https://j.021east.com/p/1703471549049630"},
            {"heat":89,"title":"应对严寒 他们这样做","summary":"虹口区12345市民服务热线应对极端天气的应急预案，热线平台按照市级平台下发工单的紧急程度，要求区热线平台10分钟内受理完毕，派遣并电话联系承办单位开展相应处置，1小时内反馈处置要点。","source_name": "抖音","source_url":"https://3g.k.sohu.com/t/n751532874"},
            {"heat":58,"title":"为干事者撑腰、为负责者担责，虹口区激励担当作为、纠治推诿扯皮","summary":"虹口区城运中心主任马良表示，在第二批主题教育中，虹口区城运中心从“12345”市民热线库中查摆不足，整改不足，不断推动热线工作稳步向前，今年以来“12345”市民热线满意率有了显著提升。","source_name": "抖音","source_url":"https://gubaf10.eastmoney.com/news,cjpl,1384531928.html"},
            {"heat":30,"title":"浦东这所高校开通“一站式”校园版“12345”热线","summary":"上海立信会计金融学院浦东校区的近万名大学生来说，这些校园生活的“难题”，如今已有了新的解决方案","source_name": "抖音","source_url":"https://baijiahao.baidu.com/s?id=1786119178593826919"},
            {"heat":28,"title":"上海通报4起形式主义、官僚主义典型问题","summary":"盛辉达在处置涉及某小区违建投诉的12345市民服务热线工单过程中，不正确履行职责，未能及时跟进工单后续处置和现场整改工作","source_name": "抖音","source_url":"http://www.flfww148.com/view.asp?id=17929"},
          ],
          "articlesShipin": [
            {"heat":100,"title":"五月天被质疑假唱。上海12345回应暂未接到投诉，如遇假唱可维权","summary":"","source_name": "腾讯视频","source_url":"https://v.qq.com/x/page/s35302vehxn.html"},
            {"heat":100,"title":"网传投靠朋友家可以落户上海？热线：并未出新政","summary":"","source_name": "爱奇艺","source_url":"https://www.iqiyi.com/v_19g92288ctk.html"}
          ],
          "articlesDuanShipin": [
            {"heat":100,"title":"大城市讲的是效率，讲的是格局","summary":"","source_name": "抖音","source_url":"https://www.iesdouyin.com/share/video/7315992391251447103/?schema_type=37"},
            {"heat":100,"title":"打12345要求停播中国好声音","summary":"","source_name": "抖音","source_url":"https://www.iesdouyin.com/share/video/7316069020086586674/?schema_type=37"},
            {"heat":100,"title":"就知道我平时看什么了 人家都说娱乐黑丝","summary":"","source_name": "抖音","source_url":"https://www.iesdouyin.com/share/video/7315562977791331635/?schema_type=37"},
            {"heat":100,"title":"特斯拉遇到事故怎么办，学会这几步处理","summary":"","source_name": "抖音","source_url":"https://www.iesdouyin.com/share/video/7316467865538891060/?schema_type=37"},
            {"heat":100,"title":"儿童乘车是否需要包容有度","summary":"","source_name": "抖音","source_url":"https://www.iesdouyin.com/share/video/7316088518126652684/?schema_type=37"},
          ],
          "articlesLuntan": [
            {"heat":100,"title":"又一批虚假检测和认证典型案例公布","summary":"","source_name": "知乎","source_url":"https://zhuanlan.zhihu.com/p/673721344"},
            {"heat":100,"title":"联动详情还没发呢，怎么对比出来的拉垮呢","summary":"","source_name": "百度贴吧","source_url":"https://tieba.baidu.com/p/8818043325"},
            {"heat":100,"title":"闯红灯行为就在那嘎达很稀疏平常的事","summary":"","source_name": "百度贴吧","source_url":"https://tieba.baidu.com/p/8815493768"},
            {"heat":100,"title":"小区里微型消防站“不见了”","summary":"","source_name": "股吧","source_url":"https://guba.eastmoney.com/o/news,gssz,1384550698.html"},
            {"heat":100,"title":"【便民服务】15号线南延伸工程开工","summary":"","source_name": "强国论坛","source_url":"https://article.xuexi.cn/articles/index.html?art_id=5094699651053870468&item_id=5094699651053870468&cdn=https%3A%2F%2Fregion-shanghai-resource&study_style_id=feeds_default"},
          ],
          "articlesWeixin": [
            {"heat":100,"title":"上海市公开曝光四起形式主义、官僚主义典型问题","summary":"","source_name": "微信","source_url":"https://mp.weixin.qq.com/s?__biz=MzU1NzczNTk3NA==&mid=2247518029&idx=2&sn=325f69031bb2d4ef09a0a7b12cb93e88&scene=0&"},
            {"heat":100,"title":"打打电话就能让人安心？闵行这项服务已惠及","summary":"","source_name": "微信","source_url":"https://mp.weixin.qq.com/s?__biz=Mzg4OTcyNzMzMA==&mid=2247527189&idx=7&sn=8659829c8ccc41468dfd680ab7789d8f&scene=0&"},
            {"heat":100,"title":"社区里的“家长里短”，原来可以这样解决","summary":"","source_name": "微信","source_url":"https://mp.weixin.qq.com/s?__biz=MzI1MjI3MTE0MQ==&mid=2247495098&idx=1&sn=01a7686a9bd3d9b8cd1b72bd9eb9cae7&scene=0&"},
            {"heat":100,"title":"青浦3人因“形式主义、官僚主义”问题被通报！","summary":"","source_name": "微信","source_url":"https://mp.weixin.qq.com/s?__biz=MzI3Mzk0MDc0NQ==&mid=2247512810&idx=1&sn=e23dffc4905f0b9575e30e7f772813a7&scene=0&"},
            {"heat":100,"title":"2023年，A股十大财务造假","summary":"","source_name": "微信","source_url":"https://mp.weixin.qq.com/s?__biz=MzkwOTQ1Nzc5OA==&mid=2247492562&idx=1&sn=fc37b352eb05f09f3dd6524bcacccb5d&chksm=c138c33bf64f4a2dd2ec701f3581100e05576c95c831f44db4c71d5ec00fefae556b7ef0da1d#rd&"},
          ],
          "articlesWeibo": [
            {"heat":100,"title":"#上海外国语大学贤达人文经济学院##求女儿朱小雪被害真相# ”","summary":"","source_name": "微博","source_url":"https://weibo.com/7480007408/NyMsvCdJo"},
            {"heat":100,"title":"对房东不退押金，非常有效，各种方式都尝试，最好投诉到信访办解决了","summary":"","source_name": "微博","source_url":"https://weibo.com/1421082581/NyMxY9Owg"},
            {"heat":100,"title":"国家信访局违背“发现一起查处一起”承诺","summary":"","source_name": "微博","source_url":"https://weibo.com/7840023339/NyMwDqxv7"},
            {"heat":100,"title":"晚会有未经许可的艺人参与","summary":"","source_name": "微博","source_url":"https://weibo.com/7500300620/NyuDiA1VY"},
            {"heat":100,"title":"是谁不让我们拿房的？12多年了","summary":"","source_name": "微博","source_url":"https://weibo.com/1911338905/NyEo2maVZ"},
          ],
          "articlesBaokan": [
            {"heat":100,"title":"微型消防站有多少“名副其实”","summary":"","source_name": "解放日报","source_url":"https://www.jfdaily.com/staticsg/res/html/journal/detail.html?date=2023-12-25&id=364885&page=05"},
            {"heat":100,"title":"临时露天菜场如何做到两月“零投诉”","summary":"","source_name": "文汇报","source_url":"https://dzb.whb.cn/2023-12-25/4/detail-836073.html"},
            {"heat":100,"title":"207处“吊脚楼”全部拆除","summary":"","source_name": "徐汇报","source_url":"http://www.xuhuibao.com/html/2023-12/25/content_106_16894072.htm"},
            {"heat":100,"title":"推进民生实事项目 用心点亮万家“明灯” ","summary":"","source_name": "国家电网报","source_url":"https://epaper.sgcctop.com/202312/25/con-716167.html"},
            {"heat":100,"title":"谱写助推长三角一体化发展“新故事”","summary":"","source_name": "江苏法治报","source_url":"https://jsfzb.xhby.net/pc/con/202312/25/content_1279306.html"},
          ],
          "articlesApp": [
            {"heat":100,"title":"12345派来一封表扬信，原来是","summary":"","source_name": "客户端","source_url":"https://3g.k.sohu.com/t/n751718501"},
            {"heat":100,"title":"上海小区里的“微型消防站”，有多少“名副其实”？","summary":"","source_name": "客户端","source_url":"https://c.m.163.com/news/a/IMPPDH150512DU6N.html"},
            {"heat":100,"title":"“基本满意”之后 | 跨前一步，补上背街小巷“差一口气”","summary":"","source_name": "客户端","source_url":"https://www.163.com/dy/article/IMPLFHEP05506BEH.html?spss=dy_author"},
            {"heat":100,"title":"老人深陷医美纠纷 对美容机构的违法、违规行为监管难在哪里? ","summary":"","source_name": "客户端","source_url":"https://www.sohu.com/a/746936805_100001237"},
            {"heat":100,"title":"今年本市建成82个一刻钟便民生活圈","summary":"","source_name": "客户端","source_url":"https://finance.sina.cn/2023-12-25/detail-imzzeste0386757.d.html"},
          ],
          "articlesZhihu": [
            {"heat":100,"title":"如何看待上海浦东新区新场古镇建造大型垃圾中转站？","summary":"","source_name": "知乎","source_url":"https://www.zhihu.com/question/636447036"},
            {"heat":100,"title":"人才引进落户上海，父母领养老金该如何操作？","summary":"","source_name": "知乎","source_url":"https://zhuanlan.zhihu.com/p/674021521"},
            {"heat":100,"title":"上海、四川两地印发医疗机构不良执业行为记分管理办法","summary":"","source_name": "知乎","source_url":"https://zhuanlan.zhihu.com/p/674006340"},
            {"heat":100,"title":"应对突发公共卫生危机的社区韧性治理","summary":"","source_name": "知乎","source_url":"https://zhuanlan.zhihu.com/p/674034627"},
            {"heat":100,"title":"又一批虚假检测和认证典型案例公布","summary":"","source_name": "知乎","source_url":"https://zhuanlan.zhihu.com/p/673721344"},
          ],
          "articlesToutiao": [
            {"heat":100,"title":"冬日里的暖心表扬，普陀这里的加梯助力美好生活？","summary":"","source_name": "今日头条","source_url":"https://www.toutiao.com/article/7316129968596173349/"},
            {"heat":100,"title":"160平米能停120辆车？破解“停车难”","summary":"","source_name": "今日头条","source_url":"https://www.toutiao.com/article/7315699884601049627/"},
            {"heat":100,"title":"矛盾无法解决，反向处理，效果真的很好","summary":"","source_name": "今日头条","source_url":"https://www.toutiao.com/article/7316144592490545698/"},
            {"heat":100,"title":"持续寒潮“冻”真格，多措并举“暖”人心","summary":"","source_name": "今日头条","source_url":"https://www.toutiao.com/article/7315990761886155291/?wid=1703506221845"},
            {"heat":100,"title":"收运单位混装混运会有什么相应的惩处措施？","summary":"","source_name": "今日头条","source_url":"https://www.toutiao.com/article/7316153160173797923/"},
          ],
          "articlesPl": [
            {"heat":100,"title":"#上海外国语大学贤达人文经济学院##求女儿朱小雪被害真相# ","summary":"","source_name": "微博","source_url":"https://weibo.com/6625434023/NyLliny3E"},
            {"heat":100,"title":"《上海市长热线给康桥黑恶势力通风报信(二)》","summary":"","source_name": "微博","source_url":"https://weibo.com/7706251390/NyKM7lWKD"},
            {"heat":100,"title":"对房东不退押金，非常有效，各种方式都尝试，最好投诉到信访办解决了","summary":"","source_name": "微博","source_url":"https://weibo.com/1421082581/NyMxY9Owg"},
            {"heat":100,"title":"#百倍用心 十分满意#【迎战寒潮，温暖守护】","summary":"","source_name": "微博","source_url":"https://weibo.com/2294823054/NyMebbhP3"},
            {"heat":100,"title":"第四十三次控告北京房山良乡派出所捏造警情推诿扯皮","summary":"https://weibo.com/5632398121/NyMvP1CWn","source_name": "微博","source_url":""},
          ],
          "articlesRdsj": [
            {"heat":100,"title":"1【浦东】【开通】 浦东这所高校开通“一站式”校园版“12345”热线","summary":"","source_name": "微博","source_url":"https://www.sohu.com/a/746485713_790178"},
            {"heat":100,"title":"【上海新华路街道】【暖心】 免费义诊、便民服务…上海新华路街道这个室内“市集”让老人暖心过冬","summary":"","source_name": "微博","source_url":"https://k.sina.cn/article_5044281310_12ca99fde020022qne.html"},
            {"heat":100,"title":"3【@北京12345北京公交】【学习】 @北京12345 北京公交应该向上海公交学习，北京公交充电大部分是充电桩，真不如上海的集电杆充电，","summary":"","source_name": "微博","source_url":"https://www.toutiao.com/w/1786060773665804/"},
          ],
          "articles": [
            {"heat":100,"title":"郑州希岸酒店暴雨后涨价被罚50万","summary":"按照有关规定，郑州希岸酒店高铁站店涉嫌利用其他手段，推动价格过快、过高上涨。 鉴于郑州希岸酒店高铁站店能主动道歉并挽回社会影响，郑州市市场监督……","source_name": "今日头条","source_url":""},
            {"heat":88,"title":"紧急辟谣！关于郑州暴雨的这些消息别信别传","summary":"随时做好安全转移的准备，选择最佳路线和目的地撤离。在不得不撤离时，准备好能自救逃生的工具。也可以搜集木盆、木材、大件泡沫塑料等适合漂浮的材……","source_name": "企鹅号","source_url":""},
            {"heat":47,"title":"河南暴雨·救援｜郑州科学大道西三环、红花寺村均有人被困","summary":"20日23时许，澎湃新闻（www.thepaper.cn)连线到该名网友郑先生，他表示，自己目前受困于红花寺老年服务中心对面一家名为“玫瑰庄园”的民房区内。……","source_name": "天天快报","source_url":""},
            {"heat":41,"title":"被暴雨淹没的郑州京广路隧道预计今日可完成抽水工作","summary":"截至23日下午17时，隧道内暗段积水长度约1.3公里，最深处还有超3米深的积水，预计24日可以完成抽水工作，届时可以看清隧道内部情况。","source_name": "搜狐号","source_url":""},
            {"heat":36,"title":"郑州铁路：受大暴雨影响的部分旅客列车逐步恢复开行","summary":"目前，铁路部门正在紧急抢通线路，尽快恢复运输秩序，但此次暴雨灾害影响较大，列车完全恢复正常运行仍需时间。 铁路部门提醒旅客，列车开行相关信息，","source_name": "趣头条","source_url":""},
            // {"heat":33,"title":"河南暴雨后续：郑州地铁仍停运 富士康一厂区停产一天 少林","summary":"另外两个厂区则正常运转中。一位航空港厂区的员工对AI财经社说，因为航空港远离市区，20日晚上受暴雨影响不大，交通出行也都正常，目前工厂里有一些人……","source_name": "新浪看点","source_url":""},
            // {"heat":13,"title":"郑州暴雨中，22只手托起“诺亚方舟”","summary":"一公里外的于婷（化名）正经历人生中最煎熬的时刻。车里坐的是她的母亲和刚从幼儿园接出来的两个孩子。","source_name": "网易号","source_url":""},
            // {"heat":8,"title":"暴雨突袭下的生命接力——郑州万名医院患者大转移纪实","summary":"7月22日，在阜外华中心血管病医院附近的锦绣路与郑开大道交叉口，救援人员利用舟艇转移人员（无人机照片）。","source_name": "网易新闻客户端","source_url":""},
            // {"heat":7,"title":"美团启动河南暴雨援助：捐款1亿元，郑州地区63万件米面……","summary":"央广网上海7月21日消息（记者唐奇云）河南遭遇特大暴雨灾情，公众日常生活遭受极大影响。记者今天（21日）上午从美团方面了解到，目前，该企业已将郑州……","source_name": "好看","source_url":""},
            // {"heat":6,"title":"河南新乡发布暴雨红色预警 郑州发布暴雨橙色预警","summary":"据郑州市气象服务中心官方微博消息，郑州市气象台2021年07月22日05时16分继续发布暴雨橙色预警信号：预计未来3小时内，郑州市区及所辖六县(市)累积降……","source_name": "趣头条客户端","source_url":""}
          ],
          "articles2": [
            {
              "heat": 100,
              "title": "百度AI开发者大会出现突发状况，李彦宏在演讲中遭遇观众泼水，现场一度陷入沉默。李彦宏淡定继续演讲。",
              "summary": "#李彦宏被泼水# 百度AI开发者大会出现突发状况，李彦宏在演讲中遭遇观众泼水，现场一度陷入沉默。李彦",
              "author": "新浪科技",
              "type": "蓝V",
              "source_url": "https://weibo.com/1642634100/HBIXZ4Iy2"
            },
            {
              "heat": 14,
              "title": "【#百度回应李彦宏被泼水# ：有人给AI“泼冷水”，我们前行决心不会改变】",
              "summary": "【#百度回应李彦宏被泼水#  ：有人给AI“泼冷水”，我们前行决心不会改变】7月3日，2019百度A",
              "author": "环球网",
              "type": "蓝V",
              "source_url": "https://weibo.com/1686546714/HBJAOs6NI"
            },
            {
              "heat": 13,
              "title": "【#李彦宏被泼水#】3日，在2019年百度AI开发者大会上，百度CEO李彦宏在演讲过程中被泼水。",
              "summary": "【#李彦宏被泼水#】3日，在2019年百度AI开发者大会上，百度CEO李彦宏在演讲过程中被泼水。现场",
              "author": "中国新闻网",
              "type": "蓝V",
              "source_url": "https://weibo.com/1784473157/HBJfS6vNa"
            },
            {
              "heat": 11,
              "title": "#向李彦宏泼水男子行拘期满#【向李彦宏泼水男子已被释放，家属称直接返回运城】",
              "summary": "#向李彦宏泼水男子行拘期满#【向李彦宏泼水男子已被释放，家属称直接返回运城】9日10时许，山西运城男",
              "author": "Vista看天下",
              "type": "蓝V",
              "source_url": "https://weibo.com/1323527941/HCEvYssIA"
            },
            {
              "heat": 10,
              "title": "#泼水男子或被行政拘留#【疑似浇水李彦宏肇事者社交信息曝光：曾提前一天现场“踩点”】",
              "summary": "#泼水男子或被行政拘留#【疑似浇水李彦宏肇事者社交信息曝光：曾提前一天现场“踩点”】7月3日上午，在",
              "author": "澎湃新闻",
              "type": "蓝V",
              "source_url": "https://weibo.com/5044281310/HBK6J5wT3"
            },
            {
              "heat": 8,
              "title": "#李彦宏被泼水#【李彦宏在百度会场被人“浇”了一整瓶水】http://t.cn/Ai0R4QZZ 7",
              "summary": "#李彦宏被泼水#【李彦宏在百度会场被人“浇”了一整瓶水】http://t.cn/Ai0R4QZZ 7",
              "author": "澎湃新闻",
              "type": "蓝V",
              "source_url": "https://weibo.com/5044281310/HBJhhemxt"
            },
            {
              "heat": 8,
              "title": "【#泼水男子最高面临拘留10日# 律师：若百度求情或免罚】",
              "summary": "【#泼水男子最高面临拘留10日# 律师：若百度求情或免罚】3日，2019年百度AI开发者大会，百度董",
              "author": "头条新闻",
              "type": "蓝V",
              "source_url": "https://weibo.com/1618051664/HBL40px9t"
            },
            {
              "heat": 8,
              "title": "【现场视频 | 百度AI开发者大会现场#李彦宏演讲被泼水# 淡定缓解】",
              "summary": "【现场视频 | 百度AI开发者大会现场#李彦宏演讲被泼水# 淡定缓解】2019年百度AI开发者大会今",
              "author": "头条新闻",
              "type": "蓝V",
              "source_url": "https://weibo.com/1618051664/HBIXKljpt"
            },
            {
              "heat": 7,
              "title": "“为什么泼的是水而不是硫酸”呢？泼水不伤人，所以它只能是一种“表达。",
              "summary": "#李彦宏被泼水#很多人既然会说“能泼水岂不也能泼硫酸”，为何就不继续追问“为什么泼的是水而不是硫酸”",
              "author": "陈迪Winston",
              "type": "金V",
              "source_url": "https://weibo.com/5655289725/HBLAss1fz"
            },
            {
              "heat": 6,
              "title": "今日上午，山西运城男子程冠旗被看守所释放家属称，释放后程冠旗会立即返回山西运城，不会在北京逗留。",
              "summary": "【向李彦宏泼水男子已被释放 家属:不会在北京逗留】今日上午，山西运城男子程冠旗被看守所释放，民警开车",
              "author": "网易新闻客户端",
              "type": "蓝V",
              "source_url": "https://weibo.com/1974808274/HCEq9pD01"
            }
          ],
          "authors": [
            {
              "name": "东明微爆料",
              "avatar": "https://tvax1.sinaimg.cn/crop.118.42.486.486.50/61e89b74ly8foeu1q3orsj20jg0epdg6.jpg",
              "home": "https://weibo.com/u/1642634100",
              "type": "蓝V",
              "articles": 1,
              "forward": 0,
              "remark": 1,
              "zan": 0
            },
            {
              "name": "嫣然一笑上海",
              "avatar": "https://tva1.sinaimg.cn/crop.17.13.195.195.50/624c6377gw1f2o7dkri5nj206d05vq38.jpg",
              "home": "https://weibo.com/u/1649173367",
              "type": "蓝V",
              "articles": 1,
              "forward": 0,
              "remark": 1,
              "zan": 0
            },
            {
              "name": "张姝张沛",
              "avatar": "https://tvax1.sinaimg.cn/crop.187.216.917.917.50/8c803935ly8fshge6o9h3j2104104td7.jpg",
              "home": "https://weibo.com/u/2357213493",
              "type": "蓝V",
              "articles": 1,
              "forward": 0,
              "remark": 1,
              "zan": 0
            },
            {
              "name": "善恶终有报_85685",
              "avatar": "https://tvax2.sinaimg.cn/crop.18.21.500.500.180/60718250ly1fe7kog3jroj20f00f03zj.jpg",
              "home": "https://weibo.com/breakingnews",
              "type": "蓝V",
              "articles": 1,
              "forward": 0,
              "remark": 1,
              "zan": 0
            },
            {
              "name": "召捕扔召刻辅",
              "avatar": "https://tva3.sinaimg.cn/crop.13.10.271.271.180/6a5ce645jw8f0pmtva9puj208c08cq3o.jpg",
              "home": "https://weibo.com/chinanewsv",
              "type": "蓝V",
              "articles": 1,
              "forward": 0,
              "remark": 1,
              "zan": 0
            },
            {
              "name": "银川联通",
              "avatar": "https://tva2.sinaimg.cn/crop.0.0.180.180.180/4ee36f05jw1e8qgp5bmzyj2050050aa8.jpg",
              "home": "https://weibo.com/vistastory",
              "type": "蓝V",
              "articles": 1,
              "forward": 0,
              "remark": 0,
              "zan": 1
            },
            {
              "name": "沉默是金",
              "avatar": "https://tvax1.sinaimg.cn/crop.11.10.275.275.180/005vnhZYly8ftjmwo0bx4j308c08cq32.jpg",
              "home": "https://weibo.com/thepapernewsapp",
              "type": "蓝V",
              "articles": 1,
              "forward": 0,
              "remark": 0,
              "zan": 1
            },
            {
              "name": "山东威海王峰岩寻求司法公正",
              "avatar": "https://tva1.sinaimg.cn/crop.0.0.640.640.180/006aJ1cpjw8ezgz8x9fncj30hs0hs0tb.jpg",
              "home": "https://weibo.com/u/5655289725?is_hot=1",
              "type": "金V",
              "articles": 1,
              "forward": 0,
              "remark": 0,
              "zan": 1
            },
            {
              "name": "荷塘月色7857",
              "avatar": "https://tvax1.sinaimg.cn/crop.0.0.1080.1080.50/854da8bdly8g2bwx6ktkbj20u00u0wg1.jpg",
              "home": "https://weibo.com/u/2236459197",
              "type": "金V",
              "articles": 1,
              "forward": 0,
              "remark": 0,
              "zan": 1
            },
            {
              "name": "漫步云端你知道",
              "avatar": "https://tvax3.sinaimg.cn/crop.0.0.479.479.50/0076Tsefgy1g1wqcncavbj30dc0df0t2.jpg?Expires=1562355638&ssig=UmLYMeo%2BDC&KID=imgbed,tva",
              "home": "https://weibo.com/u/6514804395",
              "type": "黄V",
              "articles": 14,
              "forward": 1,
              "remark": 0,
              "zan": 0
            }
          ],
          "urls": [
            {
              "heat": 100,
              "title": "原//@嫣然一笑上海:转发微博【原微博】《上海市长热线给康桥黑恶势力通风报信(二)》 (特注；去开庭被警匪勾结绑架非法拘禁47天) 2023年9月24日凌晨，杨秀婷上海12345网上再一次投诉（附图一）",
              "url": "https://weibo.com/7706251390/NyKM7lWKD"
            },
            {
              "heat": 78,
              "title": "原拼多多没提供换货入口，上海市长宁区工商局屡次（12315+12345+网上信访）说拼多多没违法，严重不作为！@廉洁上海 @拼多多 @拼多多客户服务 @上海长宁 @网络315警示 #上海市长宁区##网上",
              "url": "http://weibo.com/2162687040/NyEt2D9QQ"
            },
            {
              "heat": 66,
              "title":"原[悲伤]【原微博】#上海外国语大学贤达人文经...",
              "url": "https://weibo.com/7041122128/NyKCMCym3"
            },
            {
              "heat": 60,
              "title": "原尊敬的社会各界正义人士！全国网友。我就田峰，",
              "url": "https://weibo.com/7836648194/NyEy1xcsz"
            },
            {
              "heat": 53,
              "title": "原//@善恶终有报_85685:国家信访局违背“发现一",
              "url": "https://weibo.com/7840023339/NyMwDqxv7"
            },
            // {
            //   "heat": 40,
            //   "url": "http://t.cn/Ai0R4QZZ"
            // },
            // {
            //   "heat": 38,
            //   "url": "http://t.cn/E9eJNp8"
            // },
            // {
            //   "heat": 31,
            //   "url": "http://t.cn/Ai081bT1"
            // },
            // {
            //   "heat": 24,
            //   "url": "http://gslb.miaopai.com/stream/oUANcq3p8IvwzqFBwWmgCWSgs-wkwz0CIxpM"
            // },
            // {
            //   "heat": 10,
            //   "url": "http://gslb.miaopai.com/stream/862kfuBp-bYOZgMlDC"
            // }
          ],
          "orgs3": [
            {
              "name": "百度",
              "value": 199
            },
            {
              "name": "华为",
              "value": 15
            },
            {
              "name": "小米",
              "value": 10
            },
            {
              "name": "支付宝",
              "value": 7
            },
            {
              "name": "百度公司",
              "value": 6
            },
            {
              "name": "苹果",
              "value": 6
            },
            {
              "name": "搜狐",
              "value": 5
            },
            {
              "name": "普华永道",
              "value": 5
            },
            {
              "name": "新城控股",
              "value": 4
            },
            {
              "name": "苏宁",
              "value": 4
            }
          ],
          "persons3": [
            {
              "name": "李彦宏",
              "value": 329
            },
            {
              "name": "王耳朵",
              "value": 18
            },
            {
              "name": "刘强东",
              "value": 7
            },
            {
              "name": "王振华",
              "value": 7
            },
            {
              "name": "马云",
              "value": 5
            },
            {
              "name": "王海峰",
              "value": 4
            },
            {
              "name": "任正非",
              "value": 4
            },
            {
              "name": "习近平",
              "value": 3
            },
            {
              "name": "麦迪逊",
              "value": 3
            },
            {
              "name": "蒙娜丽莎",
              "value": 3
            }
          ],
          "authors3": [
            {
              "name": "乱花迷眼",
              "uid": "oIWsFt2J1w3tSCN0bf4qjzOhTPFk",
              "value": 100
            },
            {
              "name": "科技前沿阵地",
              "uid": "oIWsFtyj_chRMscyee7bCuK-Io8A",
              "value": 100
            },
            {
              "name": "世界名车大本营",
              "uid": "oIWsFt25Gv_63JEna7zIUPFFhUGg",
              "value": 74
            },
            {
              "name": "济南都市频道",
              "uid": "oIWsFtyJOhJ34DZUwitsPHWJ6i-U",
              "value": 72
            },
            {
              "name": "无相财经",
              "uid": "oIWsFt8VB1UtieSgVuyPEqAeesmM",
              "value": 54
            },
            {
              "name": "网易财经",
              "uid": "oIWsFt2p4rF5CnVb2JgovxkRAUVU",
              "value": 52
            },
            {
              "name": "浙江在线",
              "uid": "oIWsFt6lbvPmxsgSkOAIjObuFSj4",
              "value": 49
            },
            {
              "name": "董秘第一圈",
              "uid": "oIWsFtx_uYQ5qSvKEQPpA3VOhL1s",
              "value": 45
            },
            {
              "name": "大唐财富",
              "uid": "oIWsFt4vJfJc8sTYa666nJLio_QE",
              "value": 40
            },
            {
              "name": "集微网",
              "uid": "oIWsFt5E7xcKArwYVhQ52j7czBM8",
              "value": 40
            }
          ],
          "articles3": [
            {
              "heat": 100,
              "title": "百度CEO李彦宏演讲中被从头顶倒水 用英文质问后继续演讲",
              "summary": "李彦宏先是一愣，后来用英语问道：What's your problem？随后，李彦宏淡定继续演讲。",
              "author": "今晚新闻版块",
              "type": null,
              "source_url": "https://weixin.sogou.com/api/share?timestamp=1562142381&signature=qIbwY*nI6KU9tBso4VCd8lYSesxOYgLcHX5tlbqlMR8N6flDHs4LLcFgRw7FjTAO4yq7QMGiDj6Pcs5cO1h8W-5*iIwi-vmWlxiCvmjABKI5AHLpvilHIUPr2zTQbCnGo4eUCKn8ny4PY648TJdVsNriuEvTqD57Wr4yqbWugxWr0fhVHxrvvQq-bQ9fZfth1ZR2FlpA*5lD5b8x0VRDnCw2zxy1dRfWy8oih7wd7gI="
            },
            {
              "heat": 14,
              "title": "百度官方回应李彦宏被泼水：前行的决心不会改变",
              "summary": "百度否认此举是炒作。回应称： AI前进的道路上会有各种各样想象不到的事情发生，但我们前行的决心不会改变。",
              "author": "金旗舰美学散热器",
              "type": null,
              "source_url": "https://weixin.sogou.com/api/share?timestamp=1562157931&signature=qIbwY*nI6KU9tBso4VCd8lYSesxOYgLcHX5tlbqlMR8N6flDHs4LLcFgRw7FjTAO2tmKdpdYDEVQ6C9fC1HheI9udlBiRvxmpiV37W1SvFKqEsc5ZmP7CA9kK8aZ*K2ulMknh2pD0S9p5KvHauFC6qL9rpSXjXop0EeFXIjdDWuRtYxh-5FYfu9xXkghdtWk4QB3d-mOmPSbaEvZf6MyYZllkXlHWB5B-zSYVKFYD-M="
            },
            {
              "heat": 7,
              "title": " “宏颜获水”把百度AI开发者大会变成了段子手开发大会",
              "summary": "百度有多恶，都不是理由。 今天微博上突然冒出一个新的网络词语叫“宏颜获水”。",
              "author": "瑞亚美之宝官方",
              "type": null,
              "source_url": "https://weixin.sogou.com/api/share?timestamp=1562241961&signature=qIbwY*nI6KU9tBso4VCd8lYSesxOYgLcHX5tlbqlMR8N6flDHs4LLcFgRw7FjTAOBaCz3DJ6h*hUQzTGshvOuXxEvdj-sNQnjkVW8xJYAv6o9idwYV79yWCZ*ShSeXzWW1WvlRFiiBBOIXtUpbSNIFMaKQ*n86DTM4-pFFPfimeurCNIT9hz-ip6UEDY0LbiZtF8bWlZ2-ZeIANtNpjhS*buCMKxZP6VJKEoLWcf0Vc="
            },
            {
              "heat": 5,
              "title": "劲爆！百度李彦宏AI大会现场被拨水",
              "summary": "在百度AI开发者大会上，李彦宏为大家介绍多项百度的技术时，现场突发状况，有人上台向李彦宏泼了一整瓶矿泉水。",
              "author": "大象数据科学联盟",
              "type": null,
              "source_url": "https://weixin.sogou.com/api/share?timestamp=1562126550&signature=qIbwY*nI6KU9tBso4VCd8lYSesxOYgLcHX5tlbqlMR8N6flDHs4LLcFgRw7FjTAOA0**Ujwp-Z11ATsQXbuY4Z6HT0961Gs0Mjl2EP2rpnPwddSrJ7kXmoZAcnUtnW2LfbQL-hZEwIfAB*5jeD7C5NmdkBZT60tF*JKe7LxvimGU4rZquan2BwK8Zb8y1qHlSCM-vDshytEPvMq5AcrDaaoMTc0od-eEsFeWveuRJKA="
            },
            {
              "heat": 5,
              "title": "百度李彦宏被泼水，他的反应震惊所有人：成功人的高配，就是这五个字",
              "summary": "百度李彦宏被泼水，他的反应震惊所有人：成功人的高配，就是这五个字成功人士最高配的能力是什么？就是这五个字：能控制情绪。",
              "author": "天兰茶府",
              "type": null,
              "source_url": "https://weixin.sogou.com/api/share?timestamp=1562397286&signature=qIbwY*nI6KU9tBso4VCd8lYSesxOYgLcHX5tlbqlMR8N6flDHs4LLcFgRw7FjTAOfpUXgDfkCWaxGXufpDjnHKI*N*sYkzZWWPeZGyCULhO8PBUolaf8ce3mc4OnqSMTGfiKEuNkirQAHI29I7McRZGfzF*gsCEn9-pXdUutLKm1YkFSMcpFS4yNFCE*iMwTlHckeAH-Q-Qtx46uBQ7Gi7tQP0Eqw0LQkiFrmH-Rtqo="
            },
            {
              "heat": 5,
              "title": "向李彦宏泼水者已被民警带走 律师：涉侵害名誉权和治安违法",
              "summary": "北京京师律师事务所律师张新年表示，泼水行为可视为对李彦宏名誉权的侵害，且涉嫌治安违法。",
              "author": "每日摘闻",
              "type": null,
              "source_url": "https://weixin.sogou.com/api/share?timestamp=1562269537&signature=qIbwY*nI6KU9tBso4VCd8lYSesxOYgLcHX5tlbqlMR8N6flDHs4LLcFgRw7FjTAOuVKMlBo51VdPE5FZ-oT6BBWj37Yobmx85DSm9muoSZeho2dzBAcHsYwflrBzRrpVUK*p6qxRg5Gzr4p750HX8bElOQYkV2lqcRdyO8XIeknHzEHl1C*H2MhhIlR9u-Ni6mP5guQ-fZ-JrQ67LCZWDnxU4sZO8qARzRjiEUNqb1k="
            },
            {
              "heat": 3,
              "title": "为何被泼水的偏偏是李彦宏？",
              "summary": "上一次互联网大佬被群嘲的笑声似乎还未退去，又一大佬被群嘲。互联网行业似乎一直是非不断。",
              "author": "菲姐説",
              "type": null,
              "source_url": "https://mp.weixin.qq.com/s?src=11&timestamp=1565171300&ver=1776&signature=zhH7ZwTLsJfloKn*AsWP-*vJY*ZwJALuD*l7WRDVOnTYbfyhEUFCN*A4wK2ZR6qJQ9uEBRGqOYmp3KInbYIDsaSolzN4bNPL8Xff-xSNdD2uKFdBSTj8mvBiP-pDPpGa&new=1"
            },
            {
              "heat": 3,
              "title": "宏彦获水？",
              "summary": "李彦宏之所以能成为百度的董事长，他遇到突发事件后，还能淡定演讲，整体抗干扰能力是超级强的！",
              "author": "演讲小白成长记",
              "type": null,
              "source_url": "https://weixin.sogou.com/api/share?timestamp=1562193869&signature=qIbwY*nI6KU9tBso4VCd8lYSesxOYgLcHX5tlbqlMR8N6flDHs4LLcFgRw7FjTAOGNPtjDAy6Eh5OG58Wm-7adt4AMxUNtKCXjk4-CRgqtQXTaCEHOF2OLIkGulj1pER1MTt6TyUAWDrz5h94WSf*ggOlwjm6Vy9UAIJzmsvjzyBot9z261vQRYj4*ieis9vnRO7FJuSIbdR107exNKNdNg6vWySqZL0*xu4feUcim8="
            },
            {
              "heat": 3,
              "title": "2019年7月3日百度发行人李彦宏遭泼水，高配情商一览无遗。",
              "summary": "泼水事件最关键的不是动机，而是结果。这起泼水事件是一次对李彦宏个人形象、百度企业形象的民意调查。",
              "author": "wqy地小花园",
              "type": null,
              "source_url": "https://weixin.sogou.com/api/share?timestamp=1562631172&signature=qIbwY*nI6KU9tBso4VCd8lYSesxOYgLcHX5tlbqlMR8N6flDHs4LLcFgRw7FjTAO8FGBBEpni3SGL2c9hiylsvm4P2aH5snVjTZPvjXZxHswhb2v0cA6*to*rESRL9FoDvftjrxuQtwBHFHjBOHd6o6Yvm7mbytE5KpJGGCud57KMgpI38hRSEDcQDqxNV4r5Umo*6M8rTrcdHoEPJ2Tscu8MCRfNcRDEfqvsLXFTUE="
            },
            {
              "heat": 2,
              "title": "尴尬！百度AI开发者大会现场李彦宏遭矿水泉淋头！",
              "summary": "点击上方蓝字“互联网的大事儿”关注我们！ 今日，2019年百度AI开发者大会举行，按照惯例，李彦宏演",
              "author": "互联网的大事儿",
              "type": null,
              "source_url": "https://weixin.sogou.com/api/share?timestamp=1562139182&signature=qIbwY*nI6KU9tBso4VCd8lYSesxOYgLcHX5tlbqlMR8N6flDHs4LLcFgRw7FjTAO*5qgDVhARDxF*I30ba9nRi6J14D5QuZi6cWBstY3jdj1k8fGh2G6AC2dyNGRNOZkayJzvofe2Dg7uv06L7lip4xhqeiLBbg-jnJT39PeUeCwfGjIfLPmw9Eq0ELzgON5q3TjjgnWngHfRhKWyxIRa2ZL9l9GFbcM1NyQ4OOgFyM="
            }
          ]
        },
        loading: true,
        created: ""
      }
    },
    mounted() {
      this.loadData()
    },
    methods: {
      loadData() {
            this.title = "李彦宏被泼水"
            this.loading = false
            this.created = "2019-07-03 11:32:18"
            this.$nextTick(() => {
              this.popChart(this.data['persons'], 'persons')
              this.popChart(this.data['orgs'], 'orgs')
              this.popChart(this.data['persons2'], 'persons2')
              this.popChart(this.data['orgs2'], 'orgs2')
              this.popChart(this.data['persons3'], 'persons3')
              this.popChart(this.data['orgs3'], 'orgs3')
            })
      },
      popChart(data, id) {
        if (Array.prototype.isPrototypeOf(data) && (data.length < 1)) return false
        let maxr = data.length >= 1 ? data.sort((a, b) => { return b.value - a.value })[0].value : 0
        function randomColor() {
          // Random color
          let color = ['#f55b57', '#8bb0f1', '#838383', '#fe4f4c', '#faa975', '#63ae4a', '#f75e5b']
          let index = Math.floor((Math.random()*color.length))
          return color[index]
        }
        function Bubble(option){
          var _defaultOption = {
            width: 530,
            height: 250,
            padding:1.5,
            data:'',
            conEle:''
          };
          option = $.extend(true, _defaultOption,option);
          this.width  = option.width;
          this.height = option.height;
          this.padding= option.padding;
          this.data   = option.data;//数据url或对象,必填
          this.conEle = option.conEle;//svg容器(node或者选择器)，必填
          this.mouseenter = function(d,node){}
          this.mouseleave = function(d,node){}
          this.click = function (d) {}
        }
        Bubble.prototype.init = function(){
          var that = this,
            //1.设置颜色
            color = d3.scale.category20c(),
            //2.布局
            bubble = d3.layout.pack()
              .sort(null)
              .size([that.width,that.height])
              .radius((d) => {
                let val = d / maxr * 50
                return val < 30 ? 30 : val
              })
              .padding(that.padding),
            //3.添加svg元素
            svg = d3.select(that.conEle).append("svg")
              .attr("width", that.width)
              .attr("font-size", '12')
              .attr("height", that.height);
          //4.数据请求及图形绘制
          if(typeStr(that.data)=='[object string]'){
            d3.json(that.data,function(error,data){
              if(error) throw error;
              //1.对数据进行处理
              data = dataHandle(data);
              render(svg,bubble,that,data);
            })
          }else{
            render(svg,bubble,that,dataHandle(that.data));
          }
        }
        function typeStr(obj){
          return Object.prototype.toString.call(obj).toLowerCase();
        }
        //Returns a flattened hierarchy containing all leaf nodes under the root.
        function classes(root){
          var classes = [];                                                                                        //存储结果的数组
          /*
           * 自定义递归函数
           * 第二个参数指传入的json对象
           */
          function recurse(name, node) {
            if (node.children)                                                                                   //如果有孩子结点 （这里的children不是自带的，是json里面有的）
            {
              node.children.forEach(function(child) {                                                          //将孩子结点中的每条数据
                recurse(node.name, child);
              })
            }
            else {
              //如果自身是孩子结点的，将内容压入数组
              classes.push({ name: node.name, value: node.size,props:node.props})
            };
          }
          recurse(null, root);
          return {children: classes};
        }
        function render(view,layout,context,data,cb){
          var node = view.selectAll(".node")
            //绑定数据（配置结点）
              .data(layout.nodes(classes(data))
                .filter(function(d) {
                  //数据过滤，满足条件返回自身（没孩子返回自身，有孩子不返回，这里目的是去除父节点）
                  return !d.children;
                }))
              .enter().append("g")
              .attr("class", "node")
              .attr("transform", function(d) {
                //设定g移动
                return "translate(" + d.x + "," + d.y + ")";
              }),
            usingNodes = node.filter(function(d){
              return d.props.using;
            }),
            time = +new Date(),
            duration = 1000,
            strokeWidth = 0;
          node.append("circle")
            .attr("r", function(d) {
              //设置圆的半径
              return d.r;
            })
            .style("fill", function(d) {
              //气泡颜色
              return d.props.color;
            })
            .style("fill-opacity",1);
          node.append("text")
            .attr("dy", ".3em")
            //设置文本对齐
            .style("text-anchor", "middle")
            .style("font-size",'10px')
            .style("font-weight",'800')
            .style("fill", function (d) {
              //字体颜色
              return id == 'persons' ? "#5f99ea" : randomColor()
            })
            //根据半径的大小来截取对应长度字符串(很重要)
            .text(function(d) {
              return d.name.substring(0, d.r / 5);
            });
          function animate(){
            var nowTime = +new Date();
            if((nowTime-duration) > time) {
              time = nowTime;
              strokeWidth = 0;
            }
            strokeWidth += 0.6;
            //strokeWidth >10?strokeWidth=10:strokeWidth += 1;
            usingNodes.select("circle")
              .style("stroke-width",strokeWidth+'px')
              .style("stroke-opacity",'0.3')
              .style("stroke",function(d){
                return d.props.color;
              });
            requestAnimationFrame(animate);
          }
          animate();
          node.on('mouseenter',function(d){
            var node = this;
            context.mouseenter(d,node);
          })
          node.on('mouseleave',function(d){
            var node = this;
            context.mouseleave(d,node);
          })
          node.on('click', function (d) {
            var node = this;
            context.click(d)
          })
        }
        //定义数据处理方法
        function dataHandle(data){
          var result = {
            name:"flare",
            children:[]
          }
          data.forEach(function(ele){
            result.children.push({
              name:ele.name,
              size:ele.value,
              props:ele.props
            });
          });
          return result;
        }
        function createInfoTip(d){
          var html = '<div class="node-info fz-12"><ul>';
          html += '<li class="info-title"><span>'+d.name+'</span></li>';
          html += '<li class="info-content"><i class="bg-normal"></i><span class="info-content-label">信息数'+
            '</span><span class="info-content-text">'+d.value+'</span></li>';
          html += '</ul></div>';
          return html;
        }
        // 清除旧数据
        window.jQuery("#"+id).children().remove()
        d3.select('#'+id)
          .selectAll('*')
          .remove();
        var chartData = []
        _(data).forEach((item, i) => {
          chartData.push({
            name: item.name,
            value: item.value,
            props: {
              abnormal: false,
              color: "#f4f4f4",
              using: false
            }
          })
        })
        let element = window.document.getElementById(id)
        if (!element) return false // 切换时直接break
        var option = {
          data: chartData,
          conEle:'#'+id,
          width: 530,
          height: 350,
          padding:2
        }
        var bubble = new Bubble(option)
        bubble.click = function (d) {
          // TODO:: 列表点击事件
        }
        bubble.mouseenter = function(d,node){
          var $con = $("#"+id);
          var rectBox = $con[0].getBoundingClientRect();
          d3.select(node).style("cursor","pointer");
          $con.append(createInfoTip(d));
          $(".node-info").css({
            left: d3.event.x+20-rectBox.left,
            top: d3.event.y+20-rectBox.top
          }).show();
        }
        bubble.mouseleave = function(d){
          $(".node-info").remove();
        }
        bubble.init()
      }
    }
  }
</script>
<style scoped>
.echarts_empty{height: 250px;}
/*方案热点媒体*/
.mod-list li{height:auto;}
.fardmt .mod-list li,.rdzt .mod-list li{margin-bottom: 0;}
.fardmt .mod-list li,.rdzt2 .mod-list li{margin-bottom: 0;}
.fardmt .mod-list,.fardmt .echarts_empty,.rdzt .mod-list,.rdzt .echarts_empty{height: 396px;overflow: hidden;}
.rdzt2 {height: 285px;overflow: hidden;}
.fardmt .mod-list-title2{width: 120px;display: inline-block;}
.fardmt .mod-list-title2 img{margin-left: 5px;}
.el-progress{width: 65%;}
/*方案网媒热点主题*/
.rdzt .num{width:80px;text-align: left;height:20px;background:rgba(244,244,244,1);border-radius:10px;}
.rdzt .num var{margin:1px 10px 0 20px;display: inline-block;width: 14px;height: 16px; background-repeat:no-repeat; background-size:100% 100%;-moz-background-size:100% 100%;}
.rdzt .num .ico1,.rdht .num .ico1{background-image:url(../../../assets/images/analysis/ico1.png);}
.rdzt .num .ico2,.rdht .num .ico2{background-image:url(../../../assets/images/analysis/ico2.png);}
.rdzt .num .ico3,.rdht .num .ico3{background-image:url(../../../assets/images/analysis/ico3.png);}
.rdzt .num .ico4,.rdht .num .ico4{background-image:url(../../../assets/images/analysis/ico4.png);}
.rdzt .num em{color:rgba(0,0,0,.7);line-height: 20px;display: inline-block;vertical-align: top;}
.rdzt .mod-list-title1{width:398px;}
.rdzt h3 .num{background: none;margin-top: 0;color: #000;line-height: 36px;border-radius: 0;height: 36px;}
.rdzt2 .num{width:80px;text-align: left;height:20px;background:rgba(244,244,244,1);border-radius:10px;}
.rdzt2 .num var{margin:1px 10px 0 20px;display: inline-block;width: 14px;height: 16px; background-repeat:no-repeat; background-size:100% 100%;-moz-background-size:100% 100%;}
.rdzt2 .num .ico1,.rdht .num .ico1{background-image:url(../../../assets/images/analysis/ico1.png);}
.rdzt2 .num .ico2,.rdht .num .ico2{background-image:url(../../../assets/images/analysis/ico2.png);}
.rdzt2 .num .ico3,.rdht .num .ico3{background-image:url(../../../assets/images/analysis/ico3.png);}
.rdzt2 .num .ico4,.rdht .num .ico4{background-image:url(../../../assets/images/analysis/ico4.png);}
.rdzt2 .num em{color:rgba(0,0,0,.7);line-height: 20px;display: inline-block;vertical-align: top;}
.rdzt2 .mod-list-title1{width:398px;}
.rdzt2 h3 .num{background: none;margin-top: 0;color: #000;line-height: 36px;border-radius: 0;height: 36px;}
/*方案网媒热点文章*/
.rdwz .mod-list-title1{width:320px;}
.rdwz h3 .w-400{float: left;}
.rdwz .mod-list p{line-height: 22px;height: 44px;width:430px;float: left;padding-right:20px;display: -webkit-box !important;overflow: hidden;text-overflow: ellipsis;word-break: break-all;-webkit-box-orient:vertical;-webkit-line-clamp:2;font-size: 12px;}
.lh-36 li{height: auto;margin-bottom: 10px;}
.rdwz .el-progress{width: 15%;}
.h-350,.echarts_empty.h-350{height: 350px;}
/*方案微博热点用户*/
.user_list li{float: left;border-right: 1px solid rgba(191,191,191,.4);margin:20px 11px 0;}
.user_list li:nth-child(5n),.user_list li:last-child{border: none;}
.user_list li .tx {width:80px;position: relative;margin-top: 10px;}
.user_list .img{width: 80px;height: 80px;border-radius: 50%;cursor: pointer;}
.user_list .tx .ico{width:16px;height:16px;z-index: 10;position: absolute;bottom:10px;right:10px;background-repeat:no-repeat;background-size:100% 100%;-moz-background-size:100% 100%;}
.user_list .tx .num{width:55px;height:52px;z-index: 10;position: absolute;top:0;left:0; background-repeat:no-repeat;background-size:100% 100%;-moz-background-size:100% 100%;}
.user_list .tx .top1{background-image: url(../../../assets/images/analysis/top1.png);}
.user_list .tx .top2{background-image: url(../../../assets/images/analysis/top2.png);}
.user_list .tx .top3{background-image: url(../../../assets/images/analysis/top3.png);}
.user_list .con{width:100px;padding: 0 10px;font-size: 12px;line-height: 20px;}
.user_list .con p{height: 20px;overflow: hidden;white-space: nowrap;text-overflow:ellipsis;}
.user_list .con a{font-weight:600;color: #3F90F7;}
.user_list .con em{color: #000;}
/*热门传播URL链接*/
.url_lj .mod-list-title1{width:48%;}
.url_lj .el-progress{width:30%;}
.url_lj .mod-list .tag.rdzs{width:15%;}
/*方案微博热点文章*/
.rdwb .rdwz li{height: auto;margin-bottom: 10px;}
.rdwb .rdwz .mod-list-title2{width:58%;padding-right: 10px;display: -webkit-box !important;word-break: break-all;-webkit-box-orient:vertical;-webkit-line-clamp:2;white-space:normal;line-height: 22px;height: 44px;margin-top: 5px;}
.rdwb .rdwz .mod-list a.source{width: 120px;float: left;overflow: hidden;white-space: nowrap;text-overflow:ellipsis;}
.rdwb .rdwz .mod-list .ico{width:16px;height:16px;background-repeat:no-repeat;background-size:100% 100%;-moz-background-size:100% 100%;float: left;overflow: hidden;margin:10px 5px 0 0;display: inline;}
.rdwb .rdwz .mod-list .rdzs{width:230px;text-align: left;}
.rdwb .rdwz .mod-list .xxly{width:120px;text-align: left;}
.h-350,.echarts_empty.h-350{height: 350px;}
.comment_article .mod-list-title1{width: 72%;}
.comment_article .mod-list .tag.rdzs{width:16%;}
</style>
